import React, { useContext } from 'react';
import { AppContext } from '../Home';
import { StepsContext } from '../components/Steps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faKeyboard } from '@fortawesome/free-solid-svg-icons';

const Input = () => {
    
    const { setPhone, auth, otp, value, setValue, setInput, application, setDev, name } = useContext(AppContext);
    const { setAction, setMessage, messages, amount, supplier } = useContext(StepsContext);   

    const HandleSubmit = async (e) => {
        e.preventDefault(); 
        setInput(false); 

        if (supplier) {    
            setValue(value);
            setAction("CheckSupplier");
        }

        if (application) {    
            setValue(value);
            setAction("CheckApplication");
        }

        if (name) {    
            setValue(value);
            setAction("GetName");
        }

        if (amount) {    
            setValue(value);
            setAction("SetAmount");
        }

        if (otp) {  
            setAction("CheckOtp");
        }

        if (value.length == 9 && value.substring(0, 3) == "092") {  
            setDev(true);
            setAction("Dev");  
            setPhone("0832691437");                  
        }

        if (auth && value.length > 9) {
            if (value.length == 10) { 
                setPhone(value);              
                setAction("Phone");                 
            }
            else 
            {
                if (value.substring(0, 2) == "27") {
                    let number = 0 + value.slice(2); 
                    setValue(number);
                    setPhone(number);                
                    setAction("Phone"); 
                }

                if (value.substring(0, 3) == "+27") {
                    let number = 0 + value.slice(3); 
                    setValue(number);
                    setPhone(number);                
                    setAction("Phone"); 
                }
            }

            if (value.length < 10 || value.length > 12) {
                setMessage([...messages, {
                    entity: "bot",
                    prompt: "The phone number entered is incorrect. Please try again.",
                    options: []
                }]);

                setInput(true);
            }
        }
    }

    return (
        <form onSubmit={HandleSubmit} className='header_search_form'>
            <div className='header_search_form_info'>
                <div className='form-group'>
                    <div className='input-wrapper'>
                        <FontAwesomeIcon icon={faKeyboard} />
                        <input 
                            type='text' 
                            id='input' 
                            autoComplete='off' 
                            value={value} 
                            placeholder='Please type here' 
                            onChange={(event) => { setValue(event.target.value.replaceAll(' ', '')); }} 
                        /> 
                        <button 
                            className='custom-submit' 
                            type="submit"><FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}


export default Input;