import React from 'react';

const BgImage = () => {
    return ( 
        <ul className='list-unstyled banner_shap_img'>
            <li><img src='images/banner_shap1.png' alt=''/></li>
        </ul>
        );
    }

export default BgImage;