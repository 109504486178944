import React, { useState, useEffect } from 'react';  
import axios from "axios"; 
import Modal from 'react-bootstrap/Modal';
import CookieBar from './CookieBar';
import Cookies from "js-cookie";

const Footer = () => {
 
    const [loginModal, setLoginModal] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [disclaimer, SetDisclaimer] = useState(false);

    useEffect(() => { 
        const disclaim = Cookies.get('disclaimerConsent');
        if(disclaim === undefined) {
            SetDisclaimer(true);
        }
    }, []);

    const handleLoginModal = () => { 
        setLoginModal(true); 
    }

    const HandleSubmit = async (e) => {
        e.preventDefault();  
        setMessage("");

        const formData = new FormData(); 
        formData.append('email', email);
        formData.append('password', password);
        try {
            const response = await axios.post(window.base_url + "login/auth", formData).then(function (response) {
                if (response.data != undefined && response.data.status == 1) { 
                    setMessage("<p class='alert alert-success'>Login Successful. Please wait...</p>"); 
                    setTimeout(() => {
                    window.location.replace(window.base_url + "login/process?token="+response.data.token); 
                    }, 2000)                                        
                }
                else
                {
                    setMessage("<p class='alert alert-warning'>Incorrect Email or Password!</p>");
                }
            }); 
        }
        catch (error) {
            setMessage("<p class='alert alert-danger'>There seems to be a technical glitch. Please try again later.</p>"); 
        }
    }

    return ( 
            <footer className="doc_footer_area">
                <Modal size="" show={loginModal} onHide={() => setLoginModal(false)} >
                    <Modal.Header closeButton> <Modal.Title> Login </Modal.Title>
                        </Modal.Header>
                    <Modal.Body> 
                        <form onSubmit={HandleSubmit} method="post"> 
                            <div dangerouslySetInnerHTML={{__html: message }} />
                            <div>
                                <div className ="form-group">
                                    <label>Email address:</label>
                                    <div className ="input-group mb-3">
                                    <input 
                                        onChange={(event) => { setEmail(event.target.value); }} 
                                        value={email} 
                                        type="text" 
                                        className ="form-control" />
                                        <span className ="input-group-text">@silulumanzi.com</span>
                                    </div>
                                </div>
                                <div className ="form-group">
                                    <label>Password:</label>
                                    <input 
                                        onChange={(event) => { setPassword(event.target.value); }} 
                                        value={password} 
                                        type="password" 
                                        className ="form-control" />
                                </div>
                                <br/>
                                <div className ="mb-3">
                                <button type="submit" className ="btn btn-success">Submit</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <div className="doc_footer_bottom">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-9'>
                            <ul className="doc_footer_menu list-unstyled wow fadeInUp" data-wow-delay="0.2s">
                                <li><a href="/">Home</a></li> 
                                <li><a onClick={handleLoginModal}>Login</a></li>
                                <li><a href="#">Staff</a></li>
                                <li><a href="https://silulumanzi.com">Silulumanzi Website</a></li>
                            </ul>
                            </div>
                            <div className='col-md-3'>
                                <p className="wow fadeInUp" data-wow-delay="0.3s">© 2024 Silulumanzi <span></span></p>
                            </div>
                        </div>                        
                    </div>
                </div>

                {disclaimer ? <CookieBar/>:<></>}
            </footer> 
        );
    }

    

export default Footer;