import React, { useContext } from 'react';
import { AppContext } from '../Home';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2'; 

const Chart = () => {
    const { chartLabels, chartData } = useContext(AppContext);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
    );

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Consumption',
    },
  },
};

const labels = chartLabels;

const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Kiloliters',
        data: chartData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  
  return <Line options={options} data={data} />;
}

export default Chart;