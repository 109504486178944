import React from 'react';
import ReactDOM from 'react-dom/client'; 
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import Remote from './components/Remote';


export default function App() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const channel = params.get('channel');
     return undefined != channel ?  ( <Remote /> ) : ( <Home /> ) 
  }
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <App /> 
);

reportWebVitals();
