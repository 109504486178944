import React from 'react';

const Nav = (props) => { 
return ( 
    <nav className="navbar sticky-top">
    <div className="container">
      <div className="navbar-header">
        <a className="navbar-brand">{props.name}</a>
      </div> 
      <div className="navbar-right">  
            <a className="nav-link" href={window.base_url + "dashboard"}>Dashboard</a>  
            <a className="nav-link" href={window.base_url + "dashboard/logout"}>Logout</a> 
      </div>
    </div>
  </nav> 
    );
}

export default Nav;

