import './../Chat.css';
import 'bootstrap/dist/css/bootstrap.css'; 
import React, { useState, useEffect, useRef } from 'react';   
import { useSignal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import {faPaperPlane, faPaperclip, faStar, faXmark} from '@fortawesome/free-solid-svg-icons'; 
import { animateScroll } from "react-scroll";
import generatePDF from 'react-to-pdf'; 
import Resizer from "react-image-file-resizer";
import axios from "axios" 

  const Remote = () => {
 
    useSignals();
    const config = useSignal([]);  
    const initiated = useSignal(false); 
    const closed = useSignal(false); 
    const on = useSignal(false); 
    const chatStatus = useSignal('<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><circle cx="4" cy="12" r="3" fill="#1b9253"><animate id="svgSpinners3DotsFade0" fill="freeze" attributeName="opacity" begin="0;svgSpinners3DotsFade1.end-0.25s" dur="0.75s" values="1;0.2"/></circle><circle cx="12" cy="12" r="3" fill="#1b9253" opacity="0.4"><animate fill="freeze" attributeName="opacity" begin="svgSpinners3DotsFade0.begin+0.15s" dur="0.75s" values="1;0.2"/></circle><circle cx="20" cy="12" r="3" fill="#1b9253" opacity="0.3"><animate id="svgSpinners3DotsFade1" fill="freeze" attributeName="opacity" begin="svgSpinners3DotsFade0.begin+0.3s" dur="0.75s" values="1;0.2"/></circle></svg>'); 
    const name = useSignal(""); 
    const messages = useSignal([]); 
    const targetRef = useRef();
    const agent = useSignal("Silulumanzi"); 
    const [chat, setChat] = useState(true); 
    const [inputValue, setInputValue] = useState("");  
    const [file, setFile] = useState(); 
    const [text, setText] = useState("Please rate our service");
    const [preview, setPreview] = useState(false);
    const [rated, setRated] = useState(false);
    const [form, setForm] = useState(true);
    const [title, seTitle] = useState("Chat with us now");
    const [userId, setUserId] = useState(crypto.randomUUID());  
    const inputFile = useRef(null); 
    

    let time = 0;
    let online = false;
    let getChats; 

    useEffect(() => { 
      getConfig();      
      getChats = window.setInterval(function()
      {   
        loadData();
        updateStatus();
        
      }, 5000);
    },[]); 

    const getConfig = async () => {
        try { 
            const response = await axios.post(window.base_url + "chats/session/", {}).then(function (response) { 
                if(response.data.status != undefined && response.data.status == 1)
                {
                config.value = JSON.parse(atob(response.data.session));                    
                document.title = config.value.site_name; 
                }
            })
        } catch (e) {}
    }

    const StopIt = () =>  { 
        clearInterval(getChats);
    }
    
    const loadData = async () => {   

      if(messages.value.length > 0)
      {
        let i = messages.value.length - 1;  							
        online = parseInt(messages.value[i].timestamp) > Math.floor(Date.now() / 1000) - config.value.offline ? true : false;    
      } 
            
      if(messages.value.length > 0 && !online && agent == "Silulumanzi")
      {
        seTitle("Please leave a message");
        agent.value = "";
        setForm(true);
      }    

      if(online && initiated.value && !closed.value)
      { 
            const formData = new FormData(); 
            formData.append('userId', userId);
            formData.append('name', name.value); 
            formData.append('timestamp', time); 

          try {
              const response = await axios.post(window.base_url + "chats/client/", formData).then(function (response) { 
                if(response.data.messages != undefined)
                {
                    response.data.messages.forEach(chat => {
                      time = chat.timestamp;     
                      if(chat.userInfo.name != agent && chat.userInfo.name != name.value)
                      {
                        agent.value = chat.userInfo.name;
                      }  
                      
                      if(chat.closed == 1)
                      {
                        closed.value = true;
                      }
                      
                      messages.value = [...messages.value, chat];
                      animateScroll.scrollToBottom({
                          containerId: "message_box"
                      }); 

                  });          
                } 
            });
          } catch (e) {} 
      }   
    }

    const sendMessage = async (e) => {
      e.preventDefault();
      online = true;
      initiated.value = true;  
      let sent = Math.floor(Date.now() / 1000);
      let text = ""; 

      const form = new FormData(e.currentTarget); 
      if(form.get("name") != undefined && form.get("name") != "" && form.get("message") != "")
      {        
         name.value = form.get("name");
         text = form.get("message");           
          seTitle("Chat with");
          setForm(false);  
      } 

      else {
        if (inputValue == "") return; 
        text = inputValue;
      }
     
      const message = {
        timestamp: sent,
        userInfo: {"userId": userId, "name": name.value, "timestamp" : sent},
        value: text
      } 

      setInputValue(''); 

      const formData = new FormData(); 
      formData.append('userId', userId);
      formData.append('name', name.value); 
      formData.append('timestamp', sent);
      formData.append('message', text); 
      formData.append('channelId', userId);
      try {
        const response = await axios.post(window.base_url + "chats/send/", formData).then(function (response) { 
          if(response.data.status == 1)
          {

            setPreview(false);            
            messages.value = [...messages.value, message];

            setTimeout(function(){
              animateScroll.scrollToBottom({
                containerId: "message_box"
            }); 
          }, 1000);               
          }  
        });
      } catch (e) {} 
    }
 

    const handleFile = async (event) => {
     if(
        event.target.files[0].name.includes("png") || 
        event.target.files[0].name.includes("jpeg") || 
        event.target.files[0].name.includes("gif") || 
        event.target.files[0].name.includes("jpg")
     ){
      let fileInput = false;
      if (event.target.files[0]) {
        fileInput = true;
      }
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            event.target.files[0],
            600,
            600,
            "JPEG",
            100,
            0,
            (uri) => { 
              setInputValue(uri); 
              setFile(uri); 
              setPreview(true);  
            },
            "base64",
            500,
            500
          );
        } catch (err) { 
          
        }
      }
     }    
      else 
      {
        alert("Allowed File Types are: JPEG, PNG, GIF, JPG");
      }
    }

    const onFileClick = () => { 
      inputFile.current.click();
    };

    const sendNot = () => {
      setChat(false);
    }

    const updateStatus = () => {  

      if(!chatOffline())
        {
            chatStatus.value = "<span class='badge bg-success'>Online</span>";
            on.value = true;
        }
  
        if(slowResponse())
        { 
          chatStatus.value = "<span class='badge bg-info'>Slow Response Time</span>";  
          on.value = true;   
        }
  
        if(chatOffline())
        {
          chatStatus.value = "<span class='badge bg-danger'>Offline</span>";
          on.value = false;
        }
    }
 
    const setRating = async (rating) => { 
      setText("Please wait...");
      const formData = new FormData();
      formData.append('Rating', rating);
      formData.append('Staff', agent);
      formData.append('Name', name.value); 
      try {
          const response = await axios.post(window.base_url + "chats/rating/", formData).then(function (response) {
              if (response.data != undefined && response.data.status == 1) {
                  setRated(true); 
              }
              else {}
          });
      }
      catch (error) {}
    }

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  
    const timeConverter = (t) => {     
      var a = new Date(t * 1000);
      var today = new Date();
      var yesterday = new Date(Date.now() - 86400000);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      if (a.setHours(0,0,0,0) == today.setHours(0,0,0,0))
          return 'Today, ' + hour + ':' + min;
      else if (a.setHours(0,0,0,0) == yesterday.setHours(0,0,0,0))
          return 'Yesterday, ' + hour + ':' + min;
      else if (year == today.getFullYear())
          return date + ' ' + month + ', ' + hour + ':' + min;
      else
          return date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
    }

      const slowResponse = () => {
        return currentTime(config.value.slow_response_from, config.value.slow_response_to);
    }

    const chatOffline = () => {
      
        if(currentTime(config.value.chat_offline_from, "23:59") || tooEarly(config.value.chat_offline_to))
        {
          return true;
        }

        return false; 
    }

    const currentTime = (from, to) => { 
    let s = from.split(":");
    let e = to.split(":"); 
    let start = parseInt(s[0]) * 60 + parseInt(s[1]);
    let end   = parseInt(e[0]) * 60 + parseInt(e[1]); 
    let now = new Date();
    let time = now.getHours() * 60 + now.getMinutes();
        return time >= start && time < end;
    }

    const tooEarly = (from) => { 
        let s = from.split(":"); 
        let start = parseInt(s[0]) * 60 + parseInt(s[1]); 
        let now = new Date();
        let time = now.getHours() * 60 + now.getMinutes();
        return time < start;
    }




  
      return (   
        <div>   
             <div className={chat ? "silulu-quick-chat active" : "silulu-quick-chat"}>
                 <div className="silulu-quick-chat-box">
                    {
                        form ?
                        <div className="card">
                        <form onSubmit={sendMessage}>
                        <div className="card-header">
                            <div className="d-flex bd-highlight">  
                                <h4>{title}</h4>  
                            </div> 
                        </div>
                        <div className="card-body">  
                            <div className="form-group mb-3">
                                <label className="form-label">Department</label>
                                <div className="form-control"> 
                                  <table className="table">
                                    <tbody>
                                    <tr>
                                      <td className='state'>
                                      Call Center
                                      </td>
                                      <td className='state'>
                                        <div dangerouslySetInnerHTML={{ __html: chatStatus.value }} />
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>  
                            </div>  
                            <div className="form-group mb-3">
                                <label className="form-label">Your Name</label>
                                <input name="name" type="text" className="form-control" />
                            </div> 
                
                            <div className="form-group mb-3">
                                <label className="form-label">Message</label>
                                <textarea name="message" className="form-control" rows="8" cols="50"></textarea>
                            </div>     
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">  
                                        <button type="submit" className="btn btn-success btn-sm btn-block">Start Chat</button>
                                </div>
                            </div>     
                            </div>
                        </form> 
                        </div> : 
                        <div className="card">
                        <div className="card-header msg_head">
                        <div className="d-flex bd-highlight">
                            <div className="img_cont">
                            <img src="/images/favicon.png" className="rounded-circle user_img"/> 
                            </div>
                            <div className="user_info">
                            <span>{title} {agent}</span> 
                            </div>            
                        </div> 
                        </div>
                        <div className="card-body msg_card_body" id="message_box" ref={targetRef}>
                        {  
                            messages.value.map(function(data, key) {            
                                let msg = data.value.includes(window.base_url) ? '<a href="'+data.value+'" target="_blank" className="btn btn-light" download>Download</a>' : data.value;
                                return(<div className={data.userInfo.userId == userId ? 
                                        "d-flex justify-content-end mb-4" : "d-flex justify-content-start mb-4"} key={key}>
                                        {data.userInfo.userId != userId ? 
                                        <div className="img_cont_msg">
                                        <img src="/images/favicon.png" className="rounded-circle user_img_msg"/>
                                    </div> : ""}               
                                    <div className={data.userInfo.userId != userId ? 
                                        "msg_cotainer" : "msg_cotainer_send"}> 
                                        
                                        <div dangerouslySetInnerHTML={{ __html: msg.includes("base64") ? `<img src=${msg} />` : msg }} />              
                                        
                                        <span className={data.userInfo.userId != userId ? 
                                        "msg_time" : "msg_time_send"}>{timeConverter(data.timestamp)}</span>
                                    </div>
                                </div>)
                            })
                        } 
                        </div>
                
                        <div className="card-footer">
                        <form onSubmit={sendMessage}>
                            <div className="input-group"> 
                
                            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFile}/> 
                
                            {preview ? 
                                <div className='preview'>
                                <img src={file} />
                                <div className='preview_btn'>
                                <button className='btn btn-success'> 
                                    <FontAwesomeIcon icon={faPaperPlane}/> 
                                </button>
                                <br/><br/>
                                <button className='btn btn-danger' onClick={() => { setInputValue(""); setPreview(false); }}> 
                                    <FontAwesomeIcon icon={faXmark}/> 
                                </button>
                                </div>
                                </div> : 
                                closed.value == true ?
                                rated ?
                                <div className='rate'>                 
                                <h6>Thank you</h6> 
                                <p>Would you like a copy of the chat?</p>
                                <div className='btn-group'>
                                    <button className='btn btn-sm btn-warning' onClick={() =>  sendNot() }>No</button>
                                    <button className='btn btn-sm btn-success' onClick={() => { 
                                    generatePDF(targetRef, {filename: 'page.pdf'}); 
                                    setChat(false); } }>Yes</button>
                                </div>
                                </div> : <div className='rate'>                 
                                <h6>{text}</h6>
                                <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Dissatisfied"); }}/>  
                                <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Partially Satisfied"); }}/>
                                <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Average"); }}/> 
                                <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Satisfied"); }}/>
                                    </div>
                                :
                                <textarea 
                                    className="form-control type_msg" 
                                    value={inputValue} 
                                    onChange={(event) => { setInputValue(event.target.value); }} 
                                    placeholder="Type your message..."></textarea>
                            }
                
                            <div className="input-group-append">
                                <button className='input-group-text send_btn' onClick={sendMessage}> 
                                    <FontAwesomeIcon icon={faPaperPlane}/> 
                                </button>
                                <button className='input-group-text send_btn' onClick={onFileClick}> 
                                    <FontAwesomeIcon icon={faPaperclip}/> 
                                </button>
                            </div>
                
                            </div>
                        </form>
                        </div>
                    </div>
                    }  
                </div>             
            </div>
        </div>
      ); 
  }

  export default Remote; 