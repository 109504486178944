import './App.css';
import 'bootstrap/dist/css/bootstrap.css'; 
import React, { useState, createContext, useEffect } from 'react';  
import { useSignal } from "@preact/signals-react";
import {faCircleXmark, faComments} from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios"; 
import Nav from './components/Nav';
import BgImage from './components/BgImage';
import Articles from './components/Articles';
import Footer from './components/Footer';
import Robot from './components/Robot';
import Steps from './components/Steps';
import Message from './components/Message';
import Chats from './components/Chats';
import Chart from './components/Chart'; 

export const AppContext = createContext(null);  

function Home() {     
 
    const config = useSignal([]);  
    const [otp, setOtp] = useState(false); 
    const [auth, setAuth] = useState(false); 
    const [phone, setPhone] = useState("");
    const [input, setInput] = useState(false);   
    const [value, setValue] = useState(''); 
    const [chat, setChat] = useState(false); 
    const [name, setName] = useState("");  
    const [user, setUser] = useState("");
    const [dev, setDev] = useState(false); 
    const [session, setSession] = useState(false);
    const [sessionUser, setSessionUser] = useState(""); 
    const [application, setApplication] = useState(false); 
    const [password, setPassword] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false); 
    const [form, setForm] = useState(false);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [accountData, setAccountData] = useState({});

    useEffect(() => {    
        getConfig();
        window.sessionStorage.removeItem("name");
        },[]);
        
    const getConfig = async () => {
        try { 
            const response = await axios.post(window.base_url + "chats/session/", {}).then(function (response) { 
                if(response.data.status != undefined && response.data.status == 1)
                {
                    config.value = JSON.parse(atob(response.data.session));                    
                    document.title = config.value.site_name;
                    if(config.value.name != undefined)
                    {
                        setSession(true);
                        setSessionUser(config.value.name);
                    }                   
                }
            })
        } catch (e) {}
    }

    const handleOpenChat = () =>
    { 
        setChat(!chat);
    }
    
    return( 
        <AppContext.Provider value={
            {   config,
                input, setInput, 
                phone, setPhone,  
                value, setValue, 
                auth, setAuth,
                otp, setOtp,
                user, setUser,
                dev, setDev,
                chat, setChat,
                name, setName,  
                config, setChartData, 
                password, setPassword,
                form, setForm,
                accountData, setAccountData,
                setLoggedIn, setChartLabels,
                application, setApplication,
                chartLabels, chartData }}>   
                <div className='body_wrapper'>
        {session ? 
        <Nav name={sessionUser} /> 
        : ""}
        <section className='doc_banner_area'>          
        <BgImage />           
            <div className='doc_banner_content'>
            <div className='container'>
                <div className='row'>
                        <div className='col-md-4'>
                        {loggedIn ? <div className='chat text-center' id='graph'>
                            {accountData != {} ? <div className="profile text-center"><h5>{accountData.TITLE + " " + accountData.INITIAL + " " + accountData.SURNAME}</h5><span className="badge badge-primary">{accountData.CUSTKEY}</span></div> : ""} 
                            <Chart />
                        </div>: <Robot />} 
                        </div>
                        <div className='col-md-8'> 
                            <div id='chat' className='chat'>    
                                <Steps />           
                            </div>                                 
                        </div>
                    </div>
                </div>
            </div>
        </section>    
        <Articles /> 
        <Footer  />
            <div className={chat ? "silulu-quick-chat active" : "silulu-quick-chat"} id="siluluQuickChat">
            <button className="silulu-quick-chat-toggle" onClick={handleOpenChat}>
            <FontAwesomeIcon icon={faComments}/> 
                </button>
            <div className="silulu-quick-chat-box">
                {
                    form ?
                    <Message /> :
                    <Chats />
                }                    
                <button className="btn btn-light btn-sm btn-icon top-0 end-0 position-absolute rounded-pill translate-middle" onClick={handleOpenChat}>
                <FontAwesomeIcon icon={faCircleXmark}/>
            </button>
            </div>             
        </div>
    </div>
    
    </AppContext.Provider>
    ) 
}

export default Home;
