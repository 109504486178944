import React, { useState } from "react";
import Cookies from "js-cookie";

function CookieBar() {
  const [isCookieSet, setCookie] = useState(Cookies.get("disclaimerConsent"));
  const [isShow, setIsShow] = useState(true);

  const handleAcceptCookies = () => {
    Cookies.set("disclaimerConsent", true, { expires: 365 });
    setCookie(true);
  };

  const handleRejectCookies = () => {
    Cookies.remove("disclaimerConsent");
    setCookie(false);
  };

  return ( 
        isShow ? <div className="consent">
        <div className="banner">
          <div className="text">
          Silulumanzi (RF) (Pty) Ltd shall take all reasonable measures to protect the personal information of users and for the purpose of this disclaimer “personal information” shall be defined as detailed in the Promotion of Access to Information Act, Act 2 of 2000 (PAIA) and the Protection of Personal Information Act, Act 4 of 2013 (POPIA).
          </div>
          <div>
          <button className="btn btn-sm btn-secondary" onClick={() => {handleAcceptCookies(); setIsShow(false)}}>Ok</button> 
          </div>
        </div> 
      </div> : <></>     
  );
}

export default CookieBar;
