import React, { useState, useContext, useRef } from 'react';  
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { AppContext } from '../Home'; 
import Resizer from "react-image-file-resizer";
import axios from "axios"; 

  const Message = () => {

    const {accountData, user, setForm, setChat} = useContext(AppContext);  
    const [file, setFile] = useState();
    const [preview, setPreview] = useState(false); 
    const [inputValue, setInputValue] = useState(""); 
    const [button, setButton] = useState("Submit"); 

    const inputFile = useRef(null);  

    const handleReportSubmit = async (e) => {
        e.preventDefault(); 
        const form = new FormData(e.currentTarget);
 
        if(form.get("contact_number") != "" || form.get("email") != "")
        {
            setButton("Sending...");
            const formData = new FormData();
            formData.append("name", form.get("name"));
            formData.append("phone", form.get("contact_number"));
            formData.append("email", form.get("email"));
            formData.append("message", form.get("message")); 
            formData.append("photo", inputValue);
    
            try {
                const response = await axios.post(window.base_url + "chats/message/", formData).then(function (response) {
                    if (response.data != undefined && response.data.status == 1) {                         
                        setFile(""); 
                        setPreview(false);             
                        setForm(false);    
                        setChat(false);     
                        e.currentTarget.reset();  
                        setButton("Submit");
                    } 
                });
            }
            catch (error) {
                
            }           
        }   
    }

    const handleFile = async (event) => {
     if(event.target.files[0].name.includes("png") || 
     event.target.files[0].name.includes("jpeg") || 
     event.target.files[0].name.includes("gif") || 
     event.target.files[0].name.includes("jpg")
     ){
      let fileInput = false;
      if (event.target.files[0]) {
        fileInput = true;
      }
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            event.target.files[0],
            600,
            600,
            "JPEG",
            100,
            0,
            (uri) => { 
              setInputValue(uri); 
              setFile(uri); 
              setPreview(true);  
            },
            "base64",
            500,
            500
          );
        } catch (err) { 
          
        }
      }
     }    
      else 
      {
        alert("Allowed File Types are: JPEG, PNG, GIF, JPG");
      }
    }


    const onFileClick = () => { 
      inputFile.current.click();
    };

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
   
  
    return ( 
        <div className="card gap" id="messageForm">
            <form onSubmit={handleReportSubmit}>
            <div className="card-header">
                <div className="d-flex bd-highlight">  
                    <h4>Leave a message</h4>  
                </div> 
            </div>
            <div className="card-body">
                <div className="form-group mb-3">
                    <label>Name</label>
                    <input name="name" type="text" defaultValue={user} className="form-control" />
                </div> 
                <div className="form-group mb-3">
                    <label>Email Address</label>
                    <input name="email" type="text" defaultValue={accountData.E_MAIL != undefined ? accountData.E_MAIL.includes(",") ? accountData.E_MAIL.split(",")[0] : accountData.E_MAIL : ""} className="form-control" />
                </div>
                <div className="form-group mb-3">
                    <label>Contact Number</label>
                    <input name="contact_number" type="text" defaultValue={accountData.CELL_TEL_NO != undefined ? accountData.CELL_TEL_NO : ""} className="form-control" />
                </div> 
                <div className="form-group mb-3">
                    <label>Message</label>
                    <textarea name="message" className="form-control" rows="4" cols="50"></textarea>
                </div>     
                <div className="row">
                    <div className="col-md-5">
                        <div className="btn-group mb-3">
                        <div className='preview'>
                            <img src={file} />
                        </div>
                        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFile}/> 
                        </div> 
                    </div>
                    <div className="col-md-7"> 
                            <span className="btn btn-info" onClick={onFileClick}><FontAwesomeIcon icon={faPaperclip}/></span>
                            <button type="submit" className="btn btn-success">{button}</button>
                    </div>
                </div>     
            </div>             
            </form>
        </div>
    ); 
  }

  export default Message; 