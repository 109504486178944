import React, { useState, useEffect, useContext, useRef } from 'react';  
import { animateScroll } from "react-scroll";
import { useSignal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import {faPaperPlane, faPaperclip, faStar, faXmark} from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import generatePDF from 'react-to-pdf';
import { AppContext } from '../Home'; 
import Resizer from "react-image-file-resizer";
import axios from "axios"; 

  const Chats = () => {

    const {setForm, config, setChat} = useContext(AppContext); 

    useSignals();
    const [inputValue, setInputValue] = useState("");  
    const messages = useSignal([]); 
    const targetRef = useRef();
    const agent = useSignal("Silulumanzi"); 
    const initiated = useSignal(false); 
    const closed = useSignal(false); 
    const [file, setFile] = useState();
    const [user, setUser] = useState("");
    const [text, setText] = useState("Please rate our service");
    const [preview, setPreview] = useState(false);
    const [rated, setRated] = useState(false);
    const [userInfo, setUserinfo] = useState({
      avatar: "",
      userId:  crypto.randomUUID(),
      name: ""
    });    

    const inputFile = useRef(null); 
    let time = 0;
    let online = false;
    let getChats; 

    useEffect(() => { 
      getChats = window.setInterval(function()
      {   
        loadData();
        
      }, 5000);
    },[]); 

    const StopIt = () =>  { 
        clearInterval(getChats);
    }
    
    const loadData = async () => {   
    let u = "";
    if(user == "")
      { 
        let _user = window.sessionStorage.getItem("name"); 
        u = _user != undefined ? _user : ""; 
        setUser(u);
        userInfo.name = u;
        setUserinfo(userInfo);
      }

      if(u != "" && messages.value.length == 0){
        u = u.includes("_") ? u.split("_")[0] : u;
        const message = {
          timestamp: Math.floor(Date.now() / 1000),
          userInfo: {
            avatar: "",
            userId: 0,
            name: "Silulumanzi"
          },
          value: "Hi " + u + ", how can we help you?"          
        } 

        messages.value = [...messages.value, message];
      }
       
      if(messages.value.length > 0)
      {
        let i = messages.value.length - 1;  							
        online = parseInt(messages.value[i].timestamp) > Math.floor(Date.now() / 1000) - config.value.offline ? true : false;    
      } 
            
      if(messages.value.length > 0 && !online && agent == "Silulumanzi")
      {
         setForm(true);
      }    

      if(online && initiated.value && !closed.value)
      { 
            const formData = new FormData(); 
            formData.append('userId', userInfo.userId);
            formData.append('name', user); 
            formData.append('timestamp', time); 

          try {
              const response = await axios.post(window.base_url + "chats/client/", formData).then(function (response) { 
                if(response.data.messages != undefined)
                {
                    response.data.messages.forEach(chat => {
                      time = chat.timestamp;     
                      if(chat.userInfo.name != agent && chat.userInfo.name != user)
                      {
                        agent.value = chat.userInfo.name;
                      }  
                      
                      if(chat.closed == 1)
                      {
                        closed.value = true;
                      }
                      
                      messages.value = [...messages.value, chat];
                      animateScroll.scrollToBottom({
                          containerId: "message_box"
                      }); 
                  });          
                } 
            });
          } catch (e) {} 
      }      
    }

    const sendMessage = async (e) => {
      e.preventDefault();
      online = true;
      initiated.value = true; 
      setInputValue('');

      let u = window.sessionStorage.getItem("name");
       
      if (inputValue == "") return; 
      let sent = Math.floor(Date.now() / 1000);

      const message = {
        timestamp: sent,
        userInfo: userInfo,
        value: inputValue
      } 

      const formData = new FormData(); 
      formData.append('userId', userInfo.userId);
      formData.append('name', u); 
      formData.append('timestamp', sent);
      formData.append('message', inputValue); 
      formData.append('channelId', userInfo.userId);
      try {
        const response = await axios.post(window.base_url + "chats/send/", formData).then(function (response) { 
          if(response.data.status == 1)
          {

            setPreview(false);            
            messages.value = [...messages.value, message];

            setTimeout(function(){
              animateScroll.scrollToBottom({
                containerId: "message_box"
            }); 
          }, 1000);               
          }  
        });
      } catch (e) {} 
    }
 

    const handleFile = async (event) => {
     if(event.target.files[0].name.includes("png") || 
     event.target.files[0].name.includes("jpeg") || 
     event.target.files[0].name.includes("gif") || 
     event.target.files[0].name.includes("jpg")
     ){
      let fileInput = false;
      if (event.target.files[0]) {
        fileInput = true;
      }
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            event.target.files[0],
            600,
            600,
            "JPEG",
            100,
            0,
            (uri) => { 
              setInputValue(uri); 
              setFile(uri); 
              setPreview(true);  
            },
            "base64",
            500,
            500
          );
        } catch (err) { 
          
        }
      }
     }    
      else 
      {
        alert("Allowed File Types are: JPEG, PNG, GIF, JPG");
      }
    }

    const onFileClick = () => { 
      inputFile.current.click();
    };

    const sendNot = () => {
      setChat(false);
    }
 
    const setRating = async (rating) => { 
      setText("Please wait...");
      const formData = new FormData();
      formData.append('Rating', rating);
      formData.append('Staff', agent);
      formData.append('Name', userInfo.name); 
      try {
          const response = await axios.post(window.base_url + "chats/rating/", formData).then(function (response) {
              if (response.data != undefined && response.data.status == 1) {
                  setRated(true); 
              }
              else {}
          });
      }
      catch (error) {}
    }

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  
    const timeConverter = (t) => {     
      var a = new Date(t * 1000);
      var today = new Date();
      var yesterday = new Date(Date.now() - 86400000);
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      if (a.setHours(0,0,0,0) == today.setHours(0,0,0,0))
          return 'Today, ' + hour + ':' + min;
      else if (a.setHours(0,0,0,0) == yesterday.setHours(0,0,0,0))
          return 'Yesterday, ' + hour + ':' + min;
      else if (year == today.getFullYear())
          return date + ' ' + month + ', ' + hour + ':' + min;
      else
          return date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
    }
  
      return ( 
        <div className="card gap">
        <div className="card-header msg_head">
          <div className="d-flex bd-highlight">
            <div className="img_cont">
              <img src="/images/favicon.png" className="rounded-circle user_img"/> 
            </div>
            <div className="user_info">
              <span>Chat with {agent}</span> 
            </div>            
          </div> 
        </div>
        <div className="card-body msg_card_body" id="message_box" ref={targetRef}>
          { messages.value.map(function(data, key) {    
            let date =  new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let match = 'File-' + year + "-" + month + "-" + date.getDate();        
            let msg = data.value.includes(match) ? '<form action="'+window.base_url +'files/storage" method="post"><input type="hidden" name="file" value="'+data.value+'"><button type="submit" class="btn btn-light">Download</button></form>' : data.value;
              return(<div className={data.userInfo.userId == userInfo.userId ? 
                    "d-flex justify-content-end mb-4" : "d-flex justify-content-start mb-4"} key={key}>
                    {data.userInfo.userId != userInfo.userId ? 
                    <div className="img_cont_msg">
                    <img src="/images/favicon.png" className="rounded-circle user_img_msg"/>
                  </div> : ""}               
                  <div className={data.userInfo.userId != userInfo.userId ? 
                    "msg_cotainer" : "msg_cotainer_send"}> 
                      
                    <div dangerouslySetInnerHTML={{ __html: msg.includes("base64") ? `<img src=${msg} />` : msg }} />              
                    
                    <span className={data.userInfo.userId != userInfo.userId ? 
                    "msg_time" : "msg_time_send"}>{timeConverter(data.timestamp)}</span>
                  </div>
              </div>)
          })} 
        </div>

        <div className="card-footer">
          <form onSubmit={sendMessage}>
            <div className="input-group"> 

              <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFile}/> 

              {preview ? 
                <div className='preview'>
                <img src={file} />
                <div className='preview_btn'>
                  <button className='btn btn-success'> 
                      <FontAwesomeIcon icon={faPaperPlane}/> 
                  </button>
                  <br/><br/>
                  <button className='btn btn-danger' onClick={() => { setInputValue(""); setPreview(false); }}> 
                      <FontAwesomeIcon icon={faXmark}/> 
                  </button>
                </div>
                </div> : 
                closed.value == true ?
                 rated ?
                <div className='rate'>                 
                  <h6>Thank you</h6> 
                  <p>Would you like a copy of the chat?</p>
                  <div className='btn-group'>
                    <button className='btn btn-sm btn-warning' onClick={() =>  sendNot() }>No</button>
                    <button className='btn btn-sm btn-success' onClick={() => { 
                      generatePDF(targetRef, {filename: 'page.pdf'}); 
                      setChat(false); } }>Yes</button>
                  </div>
                </div> : <div className='rate'>                 
                  <h6>{text}</h6>
                  <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Dissatisfied"); }}/>  
                  <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Partially Satisfied"); }}/>
                  <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Average"); }}/> 
                  <FontAwesomeIcon icon={faStar} onClick={() => { setRating("Satisfied"); }}/>
                    </div>
                 :
                <textarea 
                    className="form-control type_msg" 
                    value={inputValue} 
                    onChange={(event) => { setInputValue(event.target.value); }} 
                    placeholder="Type your message..."></textarea>
              }

              <div className="input-group-append">
                <button className='input-group-text send_btn' onClick={sendMessage}> 
                    <FontAwesomeIcon icon={faPaperPlane}/> 
                </button>
                <button className='input-group-text send_btn' onClick={onFileClick}> 
                    <FontAwesomeIcon icon={faPaperclip}/> 
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
      ); 
  }

  export default Chats; 