import React from 'react';

const Robot = () => {
    return ( 
        <div id='robot'>
            <img className='robot' src='images/Robot.png' />
        </div>
        );
    }

export default Robot;